import React, { useState } from 'react';
import { createClient } from 'webdav';

function WebDAVConnector({ onEventsLoaded }) {
  const [url, setUrl] = useState('https://squad.makeitech.com/remote.php/dav/principals/users/abhilash_malkar/');
  const [username, setUsername] = useState('abhilash_malkar');
  const [password, setPassword] = useState('Abhi@1823');

  const handleConnect = async () => {
    try {
      const client = createClient(url, {
        username: username,
        password: password,
      });

      const calendarData = await client.getFileContents('/calendar.ics');
      const events = parseICSToEvents(calendarData);
      onEventsLoaded(events);
    } catch (error) {
      console.error('Error connecting to WebDAV:', error);
      alert('Failed to connect to WebDAV server. Please check your credentials and try again.');
    }
  };

  const parseICSToEvents = (icsData) => {
    // This is a simplified parser. In a real application, you'd want to use a robust ICS parser library.
    const events = [];
    const lines = icsData.split('\n');
    let currentEvent = null;

    for (const line of lines) {
      if (line.startsWith('BEGIN:VEVENT')) {
        currentEvent = {};
      } else if (line.startsWith('END:VEVENT')) {
        if (currentEvent.start && currentEvent.end && currentEvent.summary) {
          events.push(currentEvent);
        }
        currentEvent = null;
      } else if (currentEvent) {
        const [key, value] = line.split(':');
        switch (key) {
          case 'DTSTART':
            currentEvent.start = new Date(value);
            break;
          case 'DTEND':
            currentEvent.end = new Date(value);
            break;
          case 'SUMMARY':
            currentEvent.title = value;
            break;
        }
      }
    }

    return events;
  };

  return (
    <div className="webdav-connector">
      <input
        type="text"
        placeholder="WebDAV URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleConnect}>Connect to WebDAV</button>
    </div>
  );
}

export default WebDAVConnector;