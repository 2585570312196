import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './App.css';
import WebDAVConnector from './WebDAVConnector';

// Set up the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

function App() {
  const [events, setEvents] = useState([]);
  const [accountType, setAccountType] = useState('');

  const handleAccountConnect = (type) => {
    setAccountType(type);
  };

  const handleWebDAVEvents = (webdavEvents) => {
    setEvents(webdavEvents);
  };

  return (
    <div className="App">
      <h1>Calendar</h1>
      <div className="account-options">
        <button onClick={() => handleAccountConnect('WebDAV')}>Connect WebDAV</button>
        <button onClick={() => handleAccountConnect('ICS')}>Connect ICS</button>
        <button onClick={() => handleAccountConnect('Google')}>Connect Google Calendar</button>
      </div>
      {accountType && <p>Connected to: {accountType}</p>}
      {accountType === 'WebDAV' && (
        <WebDAVConnector onEventsLoaded={handleWebDAVEvents} />
      )}
      <div className="calendar-container">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </div>
    </div>
  );
}

export default App;
